body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-card {
  margin: 20px;
}

.p-card-image {
  border-radius: 10px;
  width: 300px;
  height: 300px;
  box-shadow: 0px, 0px, 3px, 1px, #ccc;
}

.p-book-image {
  border-radius: 10px;
  width: 80px;
  height: 120px;
  box-shadow: 0px, 0px, 3px, 1px, #ccc;
}

.p-book-desc {
  background-color: #57a773;
}

.p-cardsm-image {
  border-radius: 20px;
  width: 45px;
  height: 45px;
  box-shadow: 0px, 0px, 3px, 1px, #ccc;
}

.p-card-info {
  min-height: 100px;
  margin-top: 10px;
}

.p-card-title {
  font-size: 24px;
  margin: 0px;
}

.p-card-subTitle {
  font-size: 16px;
  margin: 0px;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.error-msg {
  color: #ee6352;
  padding: 10px 15px;
}
